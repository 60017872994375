import './_col-text-image.scss';

const colTextImage = function() {

};

document.addEventListener('DOMContentLoaded', function(){
    colTextImage()
}, false);

window.addEventListener('acf/loaded/col-text-image', function (e) {
    colTextImage()
});